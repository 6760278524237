@import "../../node_modules/bootstrap/scss/functions";

$enable-negative-margins: true;

$primary: #0096A9 !default;
$border-radius: .15rem !default;
$font-size-base: 0.85rem; 
$font-family-sans-serif: Roboto;

$btn-color: #fff !default;
$body-bg: #f5f5f5 !default;
$body-color: rgb(51, 51, 51) !default;
$table-color: rgb(51, 51, 51) !default;
$input-placeholder-color: #aaa !default;
$input-bg: white !default;
$table-bg: white !default;
$modal-content-bg: white !default;

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

@import "../../node_modules/bootstrap/scss/bootstrap";

.btn-primary {
	color: white;

	&:hover,
	&:focus,
	&:active {
		color: white !important;
	}
}

.form-control {
	&:focus {
		border-color: #dee2e6 !important;
		outline: none !important;
		box-shadow: none !important;
	}
}

th {
    background-color: #0096A9 !important;
    color: white !important;
	vertical-align: middle;
}

tr {
	vertical-align: middle;
}

.usage-modal {
	min-width: 1035px;
}